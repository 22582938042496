import Vue from "vue";
import VueI18n from "vue-i18n";

import frTranslation from "./fr.json";
import enTranslation from "./en.json";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: navigator.language,
  fallbackLocale: "en",
  silentFallbackWarn: true,
  messages: {
    fr: frTranslation,
    en: enTranslation
  }
});

export default i18n;

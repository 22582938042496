














import { Component, Vue } from "vue-property-decorator";
import Who from "@/components/Who.vue";
import Where from "@/components/Where.vue";
import Form from "@/components/Form.vue";

@Component({
  components: {
    Who,
    Where,
    Form
  }
})
export default class Home extends Vue {}

































import { Component, Vue } from "vue-property-decorator";

@Component
export default class Navbar extends Vue {
  whereActive = true;

  whoActive = false;

  formActive = false;

  onItemClick(id: number) {
    switch (id) {
      case 0:
        this.whereActive = true;
        this.whoActive = false;
        this.formActive = false;
        break;
      case 1:
        this.whereActive = false;
        this.whoActive = true;
        this.formActive = false;
        break;
      case 2:
        this.whereActive = false;
        this.whoActive = false;
        this.formActive = true;
        break;
    }
  }
}

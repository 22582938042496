








































































































































































import { Component, Vue } from "vue-property-decorator";
import { Address, Child, Customer, Days } from "./model/customer";
import { send } from "emailjs-com";

@Component
export default class Form extends Vue {
  customer: Customer = {
    address: {} as Address,
    child: {} as Child
  } as Customer;

  authorise = false;

  days: Days[] = [
    { label: "Lundi", afternoon: false, morning: false },
    { label: "Mardi", afternoon: false, morning: false },
    { label: "Mercredi", afternoon: false, morning: false },
    { label: "Jeudi", afternoon: false, morning: false },
    { label: "Vendredi", afternoon: false, morning: false }
  ];

  private sendEmail() {
    if (this.authorise) {
      let dayText = "";
      this.days.forEach(day => {
        dayText += ` / ${day.label} - matin : ${
          day.morning ? "Oui" : "Non"
        } - après-midi : ${day.afternoon ? "Oui" : "Non"}`;
      });
      send(
        "service_vnvta9q",
        "template_5jzl58n",
        {
          lastname: this.customer.lastname,
          firstname: this.customer.firstname,
          email: this.customer.email,
          phone: this.customer.phone,
          adress: this.customer.address.street,
          city: this.customer.address.city,
          postalCode: this.customer.address.zipCode,
          childName: this.customer.child.name,
          childBirthdate: this.customer.child.birthdate,
          start: this.customer.child.start,
          end: this.customer.child.end,
          week: dayText
        },
        process.env.VUE_APP_USER_ID
      )
        .then(() => {
          this.$toasted.success(
            "Votre pré-inscription a bien été transmise à l'équipe BébéBulle",
            { duration: 5000 }
          );
          this.$forceUpdate();
        })
        .catch(() => {
          this.$toasted.error(
            "Une erreur est survenue lors de la transmission de vos informations.",
            { duration: 5000 }
          );
        });
    }
  }
}

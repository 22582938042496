












































import { Component, Vue } from "vue-property-decorator";

@Component
export default class Where extends Vue {
  get urlMap() {
    return process.env.VUE_APP_URL_MAP;
  }
}

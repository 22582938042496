import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./translations";
import { init } from "emailjs-com";
import Toasted from "vue-toasted";

init("user_5UAeoo1Y4QHFSa13EAJP6");

Vue.config.productionTip = false;
Vue.use(Toasted);

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount("#app");
